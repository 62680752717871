<!--
  -- Created by madny on 2022/10/14
  -- 【订单详情页面】
-->
<template>
  <moe-page title="商品详情" class="orderDetail">
    <div v-if="orderDetail.id" class="pt-20">

      <div class="pt-20 pb-20" v-if="!['CANCELLED', 'ORDER_CLOSED'].includes(orderDetail.payStatus)">
        <moe-steps :stepsList="[{ title: '买家下单' }, { title: '买家付款' }, { title: '发货' }, { title: '买家确认收货' }]" :active="payStatusList[orderDetail.payStatus].payStatus"></moe-steps>
      </div>
      <div class="orderState mb-20">
        <div class="fwb">当前订单状态：{{ payStatusList[orderDetail.payStatus].text }}</div>
        <div v-for="(item, index) in payStatusList[orderDetail.payStatus].tip" :key="index">
          <div class="font-14 mt-10"> · {{ item }}</div>
        </div>
        <div class="df">
          <!-- v-if="['PENDING_SHIPMENT', 'PARTIAL_SHIPMENT'].includes(orderDetail.payStatus)" -->
          <div class="mt-10 mr-20" v-if="orderDetail.orderItemList.filter(({ canDeliver }) => canDeliver).length ? true : false">
            <el-button type="warning" icon="el-icon-box" @click="$router.push(`/order/shipment?orderNo=${orderDetail.orderNo}`)">发货</el-button>
          </div>
        </div>
      </div>
    </div>
    <moe-tabs :tabsList="tabsList" type="border-card" :value="tabsValue">
      <template #0>
        <moe-describe-list title="买家信息" :col="1">
          <moe-describe-item label="昵称">{{ orderDetail.contact }} </moe-describe-item>
          <moe-describe-item label="联系电话">{{ orderDetail.phone }} </moe-describe-item>
          <!-- <moe-describe-item label="收货地址">{{ orderDetail.address }}</moe-describe-item> -->
        </moe-describe-list>
        <!-- 挂阁 -->
        <moe-describe-list title="订单信息">
          <moe-describe-item label="订单编号">{{ orderDetail.orderNo }} </moe-describe-item>
          <moe-describe-item label="创建时间">{{ orderDetail.createTime }} </moe-describe-item>
          <moe-describe-item label="支付方式" v-if="orderDetail.payType || orderDetail.depositPayType">
            <div class="df aic" v-if="orderDetail.orderItemList.filter(({ deposit }) => $moe_math.mathGreaterThan(deposit, 0)).length && orderDetail.depositPayType">
              <div class="mr-10">定金:</div>
              <div :class="$moe_format.getpayType(orderDetail.depositPayType).color">
                <moe-icon :name="$moe_format.getpayType(orderDetail.depositPayType).icon" class="mr-5"></moe-icon>
                <span>{{ $moe_format.getpayType(orderDetail.depositPayType).title }}</span>
              </div>
            </div>

            <div class="df aic">
              <div class="mr-10" v-if="orderDetail.orderItemList.filter(({ deposit }) => $moe_math.mathGreaterThan(deposit, 0)).length">尾款:</div>
              <div v-if="orderDetail.orderItemList.filter(({ deposit, isPayBalance }) => $moe_math.mathGreaterThan(deposit, 0) && !isPayBalance).length">-</div>

              <div v-else :class="$moe_format.getpayType(orderDetail.payType).color" class="df dac">
                <moe-icon :name="$moe_format.getpayType(orderDetail.payType).icon" class="mr-5"></moe-icon>
                {{ $moe_format.getpayType(orderDetail.payType).title }}
              </div>
            </div>

           </moe-describe-item>
          <moe-describe-item label="支付时间" v-if="orderDetail.payTime">{{ orderDetail.payTime }} </moe-describe-item>
        </moe-describe-list>

        <moe-describe-list title="订单备注" :col="1">
          <moe-describe-item>{{ orderDetail.userRemark || '无备注' }}</moe-describe-item>
        </moe-describe-list>

        <moe-describe-list title="商家备注（仅后台可见）" :col="1">
          <moe-describe-item>
            <div class="df aic fww">
              <div class="mb-10">{{ orderDetail.remark || '无备注' }}</div>
              <div class="ml-10 mb-10">
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleRemark()">{{ orderDetail.remark ? '修改商家备注' : '添加商家备注' }}</el-button>
              </div>
            </div>
          </moe-describe-item>

        </moe-describe-list>

        <div style="width: 100%;">
          <moe-table style="width: 100%;" :data="orderDetail.orderItemList" :mode="false" :params="{ pageNum: 1 }" :number-show="false">
            <el-table-column prop="goodsName" label="商品信息" min-width="200">
              <template slot-scope="{ row }">
                <div class="df">
                  <moe-image :src="row.coverUrl" class="imgSty mr-10" />
                  <div style="text-align:left" class="df1">
                    <p class="fwb">{{ row.goodsName }}</p>
                    <div class="df aic">
                      <p class="font-12 color-info">{{ row.goodsItemValue }}</p>
                      <p v-if="row.fromGift" class="b-primary color-primary w-35 h-25 lh-25 tac ml-10">赠品</p>
                    </div>
                    <p class="color-danger fwb"> ￥{{ row.money }} <span class="ml-10 color-info"> x {{ row.number }}</span></p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="payStatus" label="状态" min-width="130">
              <template slot-scope="{ row }">
                <div>{{ getPayStatusComputed(row) }}</div>
                <div>{{ $moe_time.getTimeStamp(row.updateTime) }}</div>
              </template>
            </el-table-column>
            <el-table-column label="优惠金额" min-width="130">
              <div class="df fdc aic" slot-scope="{ row }">
                <div v-html="discountHtml(row.discountList)"></div>
              </div>
            </el-table-column>
            <el-table-column prop="totalPrice" label="总价" min-width="150" />
            <el-table-column label="操作" width="150" fixed="right">
              <template slot-scope="{ row }">
                <el-button v-if="['PENDING_SHIPMENT'].includes(row.payStatus) && !row.fromGift" type="danger" size="mini" icon="el-icon-thumb" @click="handleOrderRefund(row)">主动退款</el-button>
              </template>
            </el-table-column>
          </moe-table>
        </div>
        <div class="df fdc aife">
          <div class="mr-30 mb-10">运费：<span class="fwb" :class="{'color-danger': $moe_math.mathGreaterThan(orderDetail.freight) }">
            {{ $moe_math.mathGreaterThan(orderDetail.freight) ? `￥ ${orderDetail.freight}` : '包邮'  }}</span>
          </div>
          <div class="mr-30">{{ PAY_TITLE }}：<span class="color-danger fwb">￥{{ PAY_MONEY }}</span></div>
        </div>
      </template>
      <template #1>
        <div class="df aic">
          <moe-describe-list title="物流信息" :col="1" v-if="orderDetail.oldAddress">
            <div class="ml-30 color-danger">旧收货地址</div>
            <moe-describe-item label="收货人">
              <div class="df aic">
                <div>{{ orderDetail.oldContact }} , {{ orderDetail.oldMobile }}</div>
              </div>
            </moe-describe-item>
            <moe-describe-item label="收货地址">{{ orderDetail.oldAddress }} </moe-describe-item>
          </moe-describe-list>
          <moe-describe-list title="物流信息" :col="1">
            <div class="ml-30 color-danger" v-if="orderDetail.oldAddress">新收货地址</div>
            <moe-describe-item label="收货人">{{ orderDetail.contact }} , {{ orderDetail.mobile }}</moe-describe-item>
            <moe-describe-item label="收货地址">
              <div class="df aic">
                <div>{{ orderDetail.address }} </div>
                <!-- 待发货支持修改收货地址 -->
                <div class="ml-20" v-if="['PENDING_SHIPMENT'].includes(orderDetail.payStatus)">
                  <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleAddress()">修改收货地址</el-button>
                </div>
              </div>
            </moe-describe-item>
            <moe-describe-item label="包裹信息" v-if="orderDetail.expressOrderList && orderDetail.expressOrderList.length > 1">
              <div class="df">
                <div :class="['express_item box-shadow padding-20 df fdc jcsb mr-20', { 'color-primary' : expressOrderItem.expressNo === item.expressNo }]" v-for="(item, index) in orderDetail.expressOrderList" :key="index" @click="getExpress(item)">
                  <div class="df aife mb-10">
                    <div class="mr-10 font-14">包裹{{ index + 1 }} </div>
                    <div class="font-10">共{{ item.number }}件</div>
                  </div>
                  <div>{{ item.remark || '无备注' }}</div>
                </div>
              </div>
            </moe-describe-item>
          </moe-describe-list>
        </div>
        <div class="df1 df">
          <div class="df1">
            <moe-describe-list title="包裹明细" :col="1">
              <moe-describe-item label="物流单号">
                <div class="df aic">
                  <div>{{ expressOrderItem.expressNo }}</div>
                  <!-- 支持修改物流单号 -->
                  <div class="ml-20" v-if="['PARTIAL_SHIPMENT', 'PENDING_RECEIPT'].includes(orderDetail.payStatus)">
                    <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleExpress(expressOrderItem)">修改单号</el-button>
                  </div>
                </div>
              </moe-describe-item>
              <moe-describe-item label="物流公司">{{ expressOrderItem.expressName }}</moe-describe-item>
              <moe-describe-item label="发货时间">{{ $moe_time.getTimeStamp(expressOrderItem.createTime) }}</moe-describe-item>
              <moe-describe-item label="包裹中的商品">
                <div class="df" v-for="(item, index) in expressOrderItem.itemList" :key="index">
                  <moe-image :src="item.coverUrl" class="imgSty mr-10" />
                  <div class="df1 tal">
                    <p class="fwb">{{ item.goodsName }}</p>
                    <div class="df aic">
                      <p class="font-12 color-info">{{ item.specValueStr }}</p>
                      <p v-if="item.fromGift" class="b-primary color-primary w-35 h-25 lh-25 tac ml-10">赠品</p>
                    </div>
                    <p class="color-danger fwb"> ￥{{ item.payPrice }} <span class="ml-10 color-info"> x {{ item.number }}</span></p>
                  </div>
                </div>
              </moe-describe-item>
            </moe-describe-list>
          </div>
          <div class="df1">
            <moe-describe-list title="物流动态" :col="1">
              <div class="pl-30 pr-30 pt-30 pb-30">
                <moe-steps className="logisticsBox" :stepsList="stepsList" direction="vertical" :isStatus="true"></moe-steps>
              </div>
            </moe-describe-list>
          </div>
        </div>
      </template>
    </moe-tabs>

    <moe-dialog :show="showDialog" title="主动退款" width="50%" :autoHeight="true" @close="dialogClose()">
      <moe-form
        ref="refundItemForm"
        class="default-form"
        :showBack="false"
        :showTool="false"
        :model="refundParams"
        :rules="rules">
        <el-form-item label="退款原因" prop="afterSalesCause">
          <el-select v-model="refundParams.afterSalesCause">
            <el-option v-for="(item, index) in refundReasonList" :key="index" :label="item.reason" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="退款金额" v-if="orderItem.id">
          {{ `¥ ${orderItem.totalPrice}` }}
        </el-form-item>

        <el-form-item label="退款描述">
          <el-input
            type="textarea"
            placeholder="输入退款描述"
            v-model="refundParams.remark"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="req_refundItem()">确 定</el-button>
      </template>
    </moe-dialog>

    <moe-dialog :show="showDialog2" title="修改收货地址" width="50%" :autoHeight="true" @close="dialogClose()">
      <moe-form
        ref="addressForm"
        :showBack="false"
        :showTool="false"
        :model="addressParams"
        :rules="rules">
        <el-form-item label="收货人" prop="contact">
          <el-input type="text" placeholder="请输入收货人" v-model="addressParams.contact" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input type="text" placeholder="请输入手机号码" maxlength="30" :value="addressParams.mobile" @input="(value) => addressParams.mobile = $moe_formatter.formatterNoChinese(value)"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="selectList">
          <moe-address className="w-400" v-model="addressParams.selectList" :getSelectTextList="true" :selectTextList.sync="addressParams.selectTextList"></moe-address>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            type="textarea"
            placeholder="请输入详细地址"
            v-model="addressParams.address"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="100"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="req_updateAddress()">确 定</el-button>
      </template>
    </moe-dialog>

    <moe-dialog :show="showDialog3" title="修改物流单号" width="50%" :autoHeight="true" @close="dialogClose()">
      <moe-form
        ref="expressForm"
        :showBack="false"
        :showTool="false"
        :model="expressParams"
        :rules="rules">
        <el-form-item label="物流单号" prop="newExpressNo">
          <el-input type="text" placeholder="请输入物流单号" v-model.trim="expressParams.newExpressNo" :value="expressParams.newExpressNo" maxlength="20" clearable @input="(value) => changeInput(value)"></el-input>
        </el-form-item>
        <el-form-item label="快递公司" prop="newCom">
          <el-select v-model="expressParams.newCom" placeholder="请选择物流公司" filterable clearable value-key="com" @change="(value) => changeExpress(value, index)">
            <el-option :label="item.name" :value="item.com" v-for="item of expressList" :key="item.com" />
          </el-select>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="req_updateExpress()">确 定</el-button>
      </template>
    </moe-dialog>

    <moe-dialog :show="showDialog4" title="修改商家备注" width="50%" :autoHeight="true" @close="dialogClose()">
      <moe-form
        ref="remarkForm"
        class="default-form"
        :showBack="false"
        :showTool="false"
        :model="remarkParams"
        :rules="rules">
        <el-form-item label="商家备注" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入商家备注"
            v-model="remarkParams.remark"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>
      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="req_updateRemark()">确 定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    const verify = this.$moe_verify.verifyForm;
    const rules = {
      afterSalesCause: verify.isEmpty('请选择退款原因'),
      contact: verify.isEmpty('请输入收货人姓名'),
      mobile: verify.isEmpty('请输入手机号码'),
      selectList: verify.isEmpty('请选择收货地址'),
      address: verify.isEmpty('请输入详细地址'),
      newExpressNo: verify.isEmpty('请输入物流单号'),
      newCom: verify.isEmpty('请选择物流公司'),
      remark: verify.isEmpty('请输入商家备注'),
    }
    return {
      tabsList: [{ label: '订单信息' }, { label: '收货和物流信息' }],
      tabsValue:0,
      active: 0,
      orderDetail: {}, //智能设备信息
      stepsList: [],
      expressNo: '',
      expressOrderItem: {},
      orderItem: {},
      showDialog: false,
      refundReasonList: [],
      refundParams: {
        afterSalesCause: '',
        remark: '',
      },
      loading: false,
      rules,
      showDialog2: false,
      addressParams: {
        selectList: [],
        selectTextList: [],
        contact: '',
        mobile: '',
        address: '',
      },
      showDialog3: false,
      expressList: [],
      timer: null,
      expressParams: {
        expressNo: '',
        newExpressNo: '',
        newCom: '',
        newExpressName: '',
      },
      showDialog4: false,
      remarkParams: {
        remark: '',
      }
    }
  },
  computed: {
    payStatusList() {
      if (!this.orderDetail.payStatus) {
        return ''
      }
      return {
        'CANCELLED': {
          text: '买家已取消订单，交易关闭',
        },
        'PENDING_PAYMENT': {
          payStatus: 1,
          text: '买家已下单，等待买家付款',
        },
        'PENDING_SHIPMENT': {
          payStatus: 2,
          text: '买家已付款，等待商家发货',
          tip: ["1、买家已付款，请尽快发货，否则买家有权申请退款", "2、如果无法发货，请及时与买家联系并说明情况", "3、买家申请退款后，需征得卖家同意后再操作发货，否则卖家有权拒绝收货"]
        },
        'PARTIAL_SHIPMENT': {
          payStatus: 2,
          text: '买家已付款，等待商家发货',
          tip: ["1、买家已付款，请尽快发货，否则买家有权申请退款", "2、如果无法发货，请及时与买家联系并说明情况", "3、买家申请退款后，需征得卖家同意后再操作发货，否则卖家有权拒绝收货"]
        },
        'PENDING_RECEIPT': {
          payStatus: 3,
          text: '商家已发货，等待买家收货'
        },
        'COMPLETED': {
          payStatus: 4,
          text: '买家已收货，订单完成',
        },
        'ORDER_CLOSED': {
          text: '交易关闭',
        },
      }
    },
    PAY_TITLE() {
      // if (!this.orderDetail.id) {
        return `实收款`
      // }
      // const { orderItemList } = this.orderDetail
      // if (orderItemList.filter(({ deposit }) => this.$moe_math.mathGreaterThan(deposit, 0)).length) {
      //   return `实收款（包含定金）`
      // } else {
      //   return `实收款`
      // }
    },
    PAY_MONEY() {
      if (!this.orderDetail.id) {
        return 0
      }
      // const { orderItemList } = this.orderDetail
      // let payMoney = 0
      // if (orderItemList.filter(({ deposit }) => this.$moe_math.mathGreaterThan(deposit, 0)).length) {
      //   orderItemList.forEach(({ deposit, isPayBalance, payPrice, number }) => {
      //     if (isPayBalance) {
      //       payMoney = this.$moe_math.mathAdd(payMoney, this.$moe_math.mathMul(payPrice, number))
      //     } else {
      //       payMoney = this.$moe_math.mathAdd(payMoney, this.$moe_math.mathMul(deposit, number))
      //     }
      //   })
      //   return payMoney
      // } else {
        return this.orderDetail.payPrice
      // }
    }
  },
  mounted() {
    //获取商品信息
    this.getOrderDetail();
    this.queryShopRefundReasons();
    this.getAllMenuList();
    if (this.$route.query.tabsValue) {
      this.tabsValue = this.$route.query.tabsValue
    }
  },
  methods: {
    /** 弹框关闭 */
    dialogClose() {
      if (this.showDialog) {
        this.showDialog = false;
      }
      if (this.showDialog2) {
        this.showDialog2 = false;
        this.addressParams = {
          selectList: [],
          selectTextList: [],
          contact: '',
          mobile: '',
          address: '',
        }
        this.$refs['addressForm'].clearValidate();
      }
      if (this.showDialog3) {
        this.showDialog3 = false;
        this.expressParams = {
          expressNo: '',
          newExpressNo: '',
          newCom: '',
          newExpressName: '',
        }
        this.$refs['expressForm'].clearValidate();
      }
      if (this.showDialog4) {
        this.showDialog4 = false;
        this.remarkParams = {
          remark: '',
        }
        this.$refs['remarkForm'].clearValidate();
      }
    },
    /** 获取拒绝原因列表
      * type 1.退款原因 2.拒绝原因
      */
    queryShopRefundReasons() {
      this.$moe_api.ORDER_API.queryShopRefundReasons({ type: 1 }).then((data) => {
        if (data.code === 200) {
          this.refundReasonList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    discountHtml(discountList) {
      if (discountList && discountList.length) {
        let html = '', discountAll = 0;
        discountList.forEach(({ name, discount }) => {
          discountAll = this.$moe_math.mathAdd(discountAll, discount);

          html += `<div>${name}: -${discount}</div>`;
        })

        return `<div>¥ ${discountAll}</div>${html}`;
      } else {
        return '-'
      }
    },
    /** 子订单待发货主动退款 */
    handleOrderRefund(row) {
      /** 查询售后最多可退 */
      this.$moe_api.ORDER_API.afterSalesMaxMoney({ itemOrderNo: row.belowOrderNo }).then((data) => {
        if (data.code === 200) {
          this.orderItem = {
            ...row,
            totalPrice: data.result.maxMoney,
          }
          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    handleRemark() {
      if (this.orderDetail.remark) {
        this.remarkParams.remark = this.orderDetail.remark;
      }
      this.showDialog4 = true;
      this.$nextTick(() => {
        this.$refs['remarkForm'].clearValidate();
      })
    },
    handleAddress() {
      this.showDialog2 = true;
      this.$nextTick(() => {
        this.$refs['addressForm'].clearValidate();
      })
    },
    handleExpress({ expressNo }) {
      this.expressParams.expressNo = expressNo;
      this.showDialog3 = true;
      this.$nextTick(() => {
        this.$refs['expressForm'].clearValidate();
      })
    },
    changeInput(value) {
      try {
        this.expressParams.newExpressNo = this.$moe_formatter.formatterNoChinese(value).replace(/ /g, '');
        // 清除之前的定时器
        clearTimeout(this.timer)
        if (this.expressParams.newExpressNo) {
          this.timer = setTimeout(() => {
            this.$moe_api.ORDER_API.orderAutoNumber({ expressNo: this.expressParams.newExpressNo }).then((data) => {
              if (data.code === 200) {
                let list = data.result.list
                if (list.length) {
                  list.some(({ comCode }) => {
                    let findItem = this.expressList.find(({ com }) => com === comCode);
                    if (findItem) {
                      this.expressParams.newExpressName = findItem.name;
                      this.expressParams.newCom = comCode;
                      return true; // 跳出外层循环
                    }
                    return false;
                  });
                } else {
                  this.expressParams.newCom = '';
                  this.expressParams.newExpressName = '';
                }
              } else {
                this.$moe_message.error(data.message);
              }
            })
          }, 300)
        }
      } catch {
        console.log('error');
      }
    },
    changeExpress(e) {
      this.expressParams.newExpressName = this.expressList.find(({ com }) => e === com).name;
    },
    /** 修改订单商家备注 */
    async req_updateRemark() {
      this.$refs['remarkForm'].validate(async () => {
        this.loading = true;
        const { code, message } = await this.$moe_api.ORDER_API.updateRemark({
          id: this.orderDetail.id,
          remark: this.remarkParams.remark,
        })
        if (code === 200) {
          this.$moe_msg.success('修改商家备注成功', {
            completionHandler: () => {
              this.dialogClose();
              this.getOrderDetail();
            }
          });
        } else {
          this.$moe_msg.error(message);
        }
        this.loading = false;
      })
    },
    /** 修改物流单号 */
    req_updateExpress() {
      this.$refs['expressForm'].validate(() => {
        // if (this.expressParams.expressNo === this.expressParams.newExpressNo) {
        //   return this.$moe_msg.warning('新老物流单号一致 !');
        // }
        this.$moe_layer.confirm(`请确认物流信息是否无误？`, () => {
          this.loading = true;
          this.$moe_api.ORDER_API.updateExpress({
            orderNo: this.orderDetail.orderNo,
            ...this.expressParams
          }).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('修改物流信息成功', {
                completionHandler: () => {
                  this.dialogClose();
                  this.getOrderDetail();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.loading = false;
          });
        })
      })
    },
    /** 修改收货地址 */
    req_updateAddress() {
      this.$refs['addressForm'].validate(() => {
        this.$moe_layer.confirm(`请确认地址信息是否无误？`, () => {
          this.loading = true;
          const { contact, mobile, selectTextList, address } = this.addressParams;
          let params = {
            orderNo: this.orderDetail.orderNo,
            contact,
            mobile,
            address: `${selectTextList.join(' ')} ${address}`,
          }
          this.$moe_api.ORDER_API.updateAddress(params).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('修改收货地址成功', {
                completionHandler: () => {
                  this.dialogClose();
                  this.getOrderDetail();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.loading = false;
          });
        })
      })
    },
    /** 子订单退款 */
    req_refundItem() {
      this.$refs['refundItemForm'].validate(() => {
        this.loading = true;
        this.$moe_api.ORDER_API.refundItem({
          ...this.refundParams,
          itemOrderNo: this.orderItem.belowOrderNo
        }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('主动退款成功', {
              completionHandler: () => {
                this.dialogClose();
                this.getOrderDetail();
              }
            });
          } else {
            this.$moe_msg.error(data.message);
          }
        }).finally(() => {
          this.loading = false;
        });
      })
    },
    /**
     * 获取商品详情
     **/
    getOrderDetail() {
      // 获取商品ID
      const orderNo = this.$route.query.orderNo;
      // 查看商品详情
      this.$moe_api.ORDER_API.queryOrderListById({ orderNo }).then((data) => {
        if (data.code == 200) {
          let a = data.result;
          let expressOrderList = a.expressOrderList ? a.expressOrderList.map((expressOrderItem) => {
            return {
              ...expressOrderItem,
              itemList: expressOrderItem.itemList.map((itemListItem) => {
                let findItem = a.orderItemList.find(({ belowOrderNo }) => itemListItem.belowOrderNo === belowOrderNo);
                return {
                  ...itemListItem,
                  fromGift: findItem?.fromGift || false
                }
              })
            }
          }) : [];

          this.orderDetail = {
            name: a.name,
            phone: a.phone,
            orderNo: a.orderNo,
            contact: a.contact,
            mobile: a.mobile,
            address: a.address,
            oldContact: a.oldContact,
            oldMobile: a.oldMobile,
            oldAddress: a.oldAddress,
            createTime: a.createTime,
            depositPayType: a.depositPayType,
            payType: a.payType,
            payTime: a.payTime,
            orderItemList: a.orderItemList,
            expressOrderList,
            money: a.money,
            payPrice: a.payPrice,
            payStatus: a.payStatus,
            id: a.id,
            expressNo: a.expressNo,
            expressName: a.expressName,
            userRemark: a.userRemark,
            freight: a.freight,
            remark: a.remark,
          };
          if (expressOrderList && expressOrderList.length) {
            // 获取物流信息
            this.getExpress(expressOrderList[0]);
          }
          // this.active = a.payStatus
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /**
      * 获取所有物流公司信息
      **/
    getAllMenuList() {
      this.$moe_api.GENERAL_API.allExpressList().then((data) => {
        if (data.code == 200) {
          this.expressList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    // 获取物流信息
    getExpress(expressOrderItem) {
      this.expressOrderItem = expressOrderItem;
      this.$moe_api.ORDER_API.getExpress({ expressNo: expressOrderItem.expressNo }).then((data) => {
        if (data.code === 200) {
          this.logisticsData = data.result
          this.stepsList = JSON.parse(data.result.expressData).map((item, index) => {
            return {
              ...item,
              title: `【${item.status}】 ${item.context}`,
              description: item.ftime,
              status: index === 0 ? 'success' : 'process',
              icon: "el-icon-place"
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    getPayStatusComputed({ payStatus }) {
      if (['PENDING_PAYMENT'].includes(this.orderDetail.payStatus)) {
        if (this.orderDetail.orderItemList.filter(({ deposit, isPayDeposit }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayDeposit).length) {
          return `定金待支付`
        } else if (this.orderDetail.orderItemList.filter(({ deposit, isPayBalance }) => this.$moe_math.mathGreaterThan(deposit, 0) && !isPayBalance).length) {
          return `定金已支付`
        } else {
          return `待付款`
        }
      } else {
        return this.$moe_format.getPayStatus(payStatus);
      }
    }
  },
}
</script>
<style lang="scss">
.orderDetail{
  .express_item {
    border-radius: 10px;
    cursor: pointer;
  }
  .orderState{
    background: rgba(223, 166, 99, 0.1);
    padding: 20px;
  }
  .imgSty {
    width: 78px;
    display: block;
  }

  .mesStyTac {
    justify-content: center;
  }
  .el-table--fit{
    height: auto!important;
    border:1px solid #EBEEF5;
    border-bottom: 0;
  }
  .el-table__header,
  .el-table__body{
    border:0;
  }
  .el-table__body-wrapper{
    height: auto !important;
  }
  .l-describe-list{
    overflow: visible;
  }
  .el-step__head.is-success{
    color:#333;
    border-color: #333;
  }
  .el-step__title.is-success{
    color: #333;
  }
  .el-step__title.is-process{
    color: $--color-primary;
  }
  .el-step__head.is-process{
    color: $--color-primary;
    border-color: $--color-primary;
  }
}
</style>
